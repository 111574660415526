<template>
  <div class="detail-index-bg">
    <div class="detail-index">
      <div class="detail-index-crumbs">
        <span class="detail-index-crumbs-up" @click="goBack()" style="color:black;cursor:pointer"
          >{{name}}</span
        >
        /
        <!-- {{ info.book_name }} -->
        <span class="detail-index-crumbs-this">{{info.name}}</span>
      </div>
      <div class="detail-index-top detail-index-top1">
        <div class="detail-index-top-content-headPic">
          <img
            style="width: 100%; height: 100%"
            src="https://image.bookgo.com.cn/webculture/jm/border-top.png"
            alt=""
          />
        </div>
        <div class="detail-index-top-content-footerPic">
          <img
            style="width: 100%; height: 100%"
            src="https://image.bookgo.com.cn/webculture/jm/border-bottom.png"
            alt=""
          />
        </div>
        <div class="detail-index-top-left">
          <img
            style="width: 100%; height: 100%"
            :src="info.detail_pic"
            alt=""
          />
        </div>
        <div class="detail-index-top-right">
          <div class="resources-detail-top-title">{{ info.name }}</div>
          <div class="resources-detail-top-des">
            <ul class="resources-detail-top-des-ul">
              <li class="resources-detail-top-des-ul-li">
                <img
                  style="width: 0.24rem; height: 0.24rem; margin-right: 0.1rem"
                  src="https://image.bookgo.com.cn/webculture/jm/detail/detail_author_resource_t.png"
                  alt=""
                />
                <div class="resources-detail-top-des-ul-li-div">
                  <span>责任者：</span>{{ info.author }}
                </div>
              </li>
              <li class="resources-detail-top-des-ul-li">
                <img
                  style="width: 0.24rem; height: 0.24rem; margin-right: 0.1rem"
                  src="https://image.bookgo.com.cn/webculture/jm/detail/detail_publish_resource_t.png"
                  alt=""
                />
                <div class="resources-detail-top-des-ul-li-div">
                  <span>出版发行者：</span>{{ info.publisher }}
                </div>
              </li>
              <li class="resources-detail-top-des-ul-li">
                <img
                  style="width: 0.24rem; height: 0.24rem; margin-right: 0.1rem"
                  src="https://image.bookgo.com.cn/webculture/jm/detail/detail_time_resource_t.png"
                  alt=""
                />
                <div class="resources-detail-top-des-ul-li-div">
                  <span>出版发行时间：</span>{{ info.publish_time }}
                </div>
              </li>
              <li class="resources-detail-top-des-ul-li">
                <img
                  style="width: 0.24rem; height: 0.24rem; margin-right: 0.1rem"
                  src="https://image.bookgo.com.cn/webculture/jm/detail/detail_type_resource_t.png"
                  alt=""
                />
                <div class="resources-detail-top-des-ul-li-div">
                  <span>分类：</span>{{ info.book_category }}
                </div>
              </li>
            </ul>
            <div @click="addPdf(info.urls[0])" v-if="info.urls.length != 0" class="addPdfStyle">立即阅读</div>
          </div>
        </div>
      </div>
      <div class="detail-index-bottom">
        <div class="detail-index-bottom-content">
          <div class="detail-index-top-content-footerPic">
            <img
              style="width: 100%; height: 100%"
              src="https://image.bookgo.com.cn/webculture/jm/border-bottom.png"
              alt=""
            />
          </div>
          <div class="detail-index-bottom-content-top">
            <div class="detail-index-bottom-content-top-title">书籍详情</div>
          </div>

          <div class="detail-index-bottom-content-bottom">
            <div class="detail-index-bottom-content-bottom-box">
              <div class="detail-index-bottom-content-bottom-box-top">
                <div class="detail-index-bottom-content-bottom-box-top-icon">
                  <img
                    style="width: 100%; height: 100%"
                    src="https://image.bookgo.com.cn/webculture/jm/detail/detail_title_left_icon.png"
                    alt=""
                  />
                </div>
                <div class="detail-index-bottom-content-bottom-box-top-title">
                  内容简介
                </div>
              </div>
              <div class="detail-index-bottom-content-bottom-box-bottom">
                <div v-html="info.des">{{ info.des }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <el-dialog
      :close-on-click-modal="false"
      title="提示"
      :visible.sync="formDialogShow"
      :center="true"
    >
      <Cpopup
        :detailSrc="detailSrc"
        :detailType="detailType"
        :title="popupTitle"
        @handle-closed="handleClosed"
      />
    </el-dialog>
  </div>
</template>

<script>
// import axios from "axios";
import Cdetail from "../components/Cdetail";
import { queryResourceDetail, queryResourceList } from "@/api/resource";
// import { queryResourceDetail } from "@/api/resource";
import Cpopup from "../components/Popup";
import moment from "moment";
export default {
  components: { Cdetail, Cpopup },
  data() {
    return {
      pageLoading: true,
      detailSrc: "",
      info: {},
      detailType: "resources_t",
      popupTitle: "扫描二维码立即阅读",
      formDialogShow: false,
      resources_team_list: [],
      name:this.$route.query.name
    };
  },
  created() {
    let id = this.$route.query.id;
    if (!id) {
      this.$router.push({ path: "/" });
    }
    this.id = id;
  },
  mounted() {
    document.documentElement.scrollTop = 0;
    this.getResourceInfo();
  },
  watch: {
    $route: function(newVal) {
      // console.log(newVal,oldVal);
      this.id = newVal.query.id;
      this.getResourceInfo();
    },
  },
  methods: {
    getResourceInfo() {
      queryResourceDetail({ id: this.id }).then((res) => {
        console.log('----------------')
        console.log(res);
        console.log(res.data.publish_time)
        this.pageLoading = false;
        var formt = ""
        if(res.data.publish_time.length == 4) {
          formt = "YYYY"
        }else{
          formt = "YYYY-MM"
        }
        res.data.publish_time = moment(res.data.publish_time).format(formt);
        this.info = res.data;
      });
    },
    addPdf(val) {
      console.log(val)
      window.open(val.url)
      // window.location.href="https://audio.bookgo.com.cn/other/1648610659_v.pdf"
    },
    handleClosed(reload) {
      this.formDialogShow = false;
    },
    goBack() {
      let routeUrl = this.$router.resolve({
        path: `/resource`,
        query: { id:this.$route.query.pid,cat_id:this.$route.query.cat_id,navId:this.$route.query.navId,name:this.$route.query.name},
      });
      window.location = routeUrl.href
    }
  },
};
</script>

<style lang="scss">
@import "../../assets/scss/detail.scss";
@import "../../assets/scss/resources_tdetail.scss";
@import "../../assets/scss/dialog.scss";
</style>
